import React, { FC, lazy, Suspense, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, redirect } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { Context } from "../store/store";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Api } from "../apis";
import PublicRoute from "./PublicRoute";
import Login from "../Screens/Auth/Login";
import { makeStyles } from "@mui/styles";
import RestrictedRoute from "./RestrictedRoute";
import Fallback from "../Screens/Loader/Fallback";
import Claims from "../Screens/Claims/Claims";
import Summary from "../Screens/Claims/Summary/Summary";
import CreateProject from "../Screens/Claims/NewProject/CreateProject";
import ViewProject from "../Screens/Claims/ViewProject/ViewProject";
import References from "../Screens/Claims/References/References";
import Comparison from "../Screens/Claims/Comparison/Comparison";
import Tables from "../Screens/Claims/Tables/Tables";
import PageNotFound from "../Screens/Auth/PageNotFound";
import ChatBox from "../Screens/Claims/Chat/ChatBox";
import VerifyEmail from "../Screens/Auth/VerifyEmail";
import SideBar from "../Screens/SideBar";
import Translate from "../Screens/Translate/Translate";
import DocumentRepot from "../Screens/Translate/DocumentRepot";

// new UI
const ClaimsNew = lazy(() => import("../Screens/New_Claims/Claims/Claims"));
const Logo = lazy(() => import("../Icons/NewUI/Logo"));
const SidebarNew = lazy(() => import("../Screens/New_Claims/SideBar"));

const RouterConfig: FC = ({ auth }: any) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [loading, setLoading] = useState<boolean>(false);
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const classes = useStyles();

    const handleFetchPDF = () => {
        getAccessTokenSilently()
            ?.then(async (token) => {
                try {
                    if (token) {
                        setContext({
                            ...contextStore,
                            privileged_token: token,
                        });
                        setLoading(() => true);
                        // axios
                        //     .create({
                        //         headers: {
                        //             Authorization: `Bearer ${token}`,
                        //             "Content-Type": "application/json; charset=utf-8",
                        //         },
                        //     })
                        //     ?.get(Api?.getUserInfo)
                        //     ?.then(async (profile: any) => {
                        let userData: any;
                        // if (profile?.status === 401) {
                        //     setTimeout(() => {
                        //         window.location.reload();
                        //     }, 3000);
                        // }
                        // if (profile?.status === 200) {
                        //     userData = profile?.data?.data?.[0];
                        // }
                        setContext({
                            ...contextStore,
                            profile: { userId: contextStore?.user_id },
                            loading: false,
                        });
                        setLoading(() => false);
                        // })
                        // ?.catch((err) => {
                        //     setLoading(() => false);
                        //     setContext({
                        //         ...contextStore,
                        //         snackState: {
                        //             open: true,
                        //             errorType: "error",
                        //             message: "Please clear the cache and retry for better experience.",
                        //         },
                        //     });
                        // });
                    }
                } catch (error) {
                    console.log(error);
                }
            })
            ?.catch((err) => {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Error occured while authenticating the user. Please Try again.",
                    },
                });
            });
    };

    useEffect(() => {
        handleFetchPDF();
    }, []);

    return (
        <Router>
            <Routes>
                <Route
                    path="/login"
                    element={
                        <PublicRoute>
                            <Suspense>
                                <Login />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/verify"
                    element={
                        <PublicRoute>
                            <Suspense>
                                <VerifyEmail />
                            </Suspense>
                        </PublicRoute>
                    }
                />
            </Routes>

            <RestrictedRoute>
                <Box className={classes?.container}>
                    <Routes>
                        <Route path="/*" element={<Suspense fallback={<Fallback />}>{loading ? <Fallback /> : <ChatRoutes />}</Suspense>} />
                        <Route path="/new" element={<Suspense fallback={<Fallback />}>{loading ? <Fallback /> : <NewUI />}</Suspense>} />
                    </Routes>
                </Box>
            </RestrictedRoute>
        </Router>
    );
};

const useStyles = makeStyles({
    container: {
        height: "100vh",
        background: "#FEFDFD",
        display: "flex",
        flexDirection: "column",
    },
    subContainer: {
        display: "flex",
        flex: 1,
    },
});

const ChatRoutes = () => {
    const classes = useStyles();
    return (
        <Box className={classes?.subContainer}>
            <SideBar />
            <Routes>
                {[
                    { redirect: "/", element: Claims },
                    { redirect: "/claims", element: Claims },
                    { redirect: "/claims/summary", element: Summary },
                    { redirect: "/claims/new_project", element: CreateProject },
                    { redirect: "/claims/reports", element: ViewProject },
                    { redirect: "/claims/reference", element: References },
                    { redirect: "/claims/comparison", element: Comparison },
                    { redirect: "/claims/chat", element: ChatBox },
                    { redirect: "/claims/tables", element: Tables },
                    { redirect: "/translate", element: Translate },
                    { redirect: "/translate/report", element: DocumentRepot },
                    { redirect: "*", element: PageNotFound },
                ]?.map((elem) => (
                    <Route
                        key={elem?.redirect}
                        path={elem?.redirect}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <elem.element />
                            </Suspense>
                        }
                    />
                ))}
            </Routes>
        </Box>
    );
};

const NewUI = () => {
    const classes = useStyles();
    return (
        <Stack flex={1}>
            <Stack sx={{ boxShadow: "1px 0px 21px 0px rgba(83, 124, 204, 0.08)", py: 1, px: 3 }}>
                <Logo />
            </Stack>
            <Stack direction={"row"} flex={1}>
                <SidebarNew />
                <Routes>
                    {[{ redirect: "/", element: ClaimsNew }]?.map((elem) => (
                        <Route
                            key={elem?.redirect}
                            path={elem?.redirect}
                            element={
                                <Suspense fallback={<Fallback />}>
                                    <elem.element />
                                </Suspense>
                            }
                        />
                    ))}
                </Routes>
            </Stack>
        </Stack>
    );
};

export default RouterConfig;
